<template>
  <div class="single-bridge-common">
    <iframe id="iframeVr" src="http://116.211.118.94:7999/raster/index.html?system=djk" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
  export default {
    name: "optical"
  }
</script>

<style scoped>

</style>